<template>
	<b-container class="html-editor" :fixed="!dynamicArea" fluid>
		<div v-if="placeholder && !content" v-html="placeholder" class="placeholder"></div>
		<div v-if="dynamicArea"
			 ref="editor"
			 @input="inputContent()"
			 @focus="$emit('focus', $event); updateTools()"
			 @blur="$emit('blur', $event)"
			 @click="$emit('click', $event); updateTools()"
			 @keydown="$emit('keydown', $event); updateTools()"
			 @keypress="$emit('keypress', $event); updateTools()"
			 @keyup="$emit('keyup', $event); updateTools()"
			 :contenteditable="editable"></div>
		<div class="html-editor-tools" v-if="toolbar.length > 0">
			<i v-for="tool in toolbar"
			   :class="!tool.onActive ? tool.icon + (tool.active ? ' active' : '') : (tool.active ? tool.onActive.icon + ' active' : tool.icon)"
			   :title="tool.active && tool.onActive != null ? tool.onActive.label : tool.label"
			   @mousedown.prevent="toolAction(tool.active && tool.onActive != null ? tool.onActive.action : tool.action, $event)">
				<input v-if="tool.id == 'IMG'" id="file-input" ref="file-input" type="file" accept="image/*" @change="loadImage" />
				<label for="file-input"></label>
			</i>
			<div v-if="toolState.V" class="html-editor-variable-list app-scroll-custom m-1 pt-1">
				<b>{{varsLabel == null ? "Variáveis" : varsLabel}}</b>
				<ul>
					<li v-for="variable in varsList" @mousedown.prevent="getToolData('V').insertVariable(variable.value, variable.label)">{{variable.label}}</li>
				</ul>
			</div>
		</div>
		<div v-if="!dynamicArea"
			 ref="editor"
			 class="app-scroll-custom"
			 @input="inputContent()"
			 @focus="$emit('focus', $event); updateTools()"
			 @blur="$emit('blur', $event)"
			 @click="$emit('click', $event); updateTools()"
			 @keydown="$emit('keydown', $event); updateTools()"
			 @keypress="$emit('keypress', $event); updateTools()"
			 @keyup="$emit('keyup', $event); updateTools()"
			 :contenteditable="editable"></div>
		<b-modal v-if="tools.split('|').includes('IMG')"
				 :static="true"
				 id="insert-image"
				 title="Inserir imagem no corpo da mensagem"
				 @hide="resetInsertImageModal">
			<div id="select-image-container" ref="image-container">
				<div class="app-scroll-custom">
					<img :src="loadedImage.file?.src" :width="loadedImage.width" :height="loadedImage.height" />
				</div>
			</div>
			<b-container v-if="loadedImage.file" class="p-0">
				<b-row align-v="center">
					<b-col class="data-field">
						<label for="image-width">Largura (pixels)</label>
						<input id="image-width" ref="image-width" type="number" v-model="loadedImage.width" />
					</b-col>
					<b-col cols="12" md="auto">
						<i v-if="loadedImage.linkedSizes"
						   class="fas fa-link cursor-pointer"
						   @click="loadedImage.linkedSizes = false"
						   title="Desativar cálculo automático de dimensões"></i>
						<i v-else
						   class="fas fa-unlink text-secondary cursor-pointer"
						   @click="linkImageSizes"
						   title="Ativar cálculo automático de dimensões"></i>
					</b-col>
					<b-col class="data-field">
						<label for="image-height">Altura (pixels)</label>
						<input id="image-height" ref="image-height" type="number" v-model="loadedImage.height" />
					</b-col>
				</b-row>
			</b-container>
			<template #modal-footer="{ cancel }">
				<b-button variant="light"
						  @click="cancel()"
						  class="rounded-0"
						  :disabled="loaders.loadingImage">Cancelar</b-button>
				<b-button variant="success"
						  @click="insertImage"
						  class="rounded-0"
						  :disabled="loaders.loadingImage || !loadedImage.file">
					<span v-if="loaders.loadingImage">
						<b-spinner small class="mr-1"></b-spinner>
						Processando...
					</span>
					<span v-else>Confirmar</span>
				</b-button>
			</template>
		</b-modal>
	</b-container>
</template>
<script>
	import axios from "axios";

	export default {
		name: "HtmlEditor",
		props: {
			content: null,
			tools: {
				default: "B|I|U|S|A|IMG|V"
			},
			varsLabel: null,
			varsList: null,
			editable: {
				default: true
			},
			dynamicArea: {
				default: true
			},
			placeholder: null,
			plainTextOnPaste: {
				default: true
			}
		},
		model: {
			prop: "content",
			event: "input"
		},
		data() {
			return {
				toolState: {
					B: false,
					I: false,
					U: false,
					S: false,
					A: false,
					V: false,
					IMG: false
				},
				loadedImage: {
					file: null,
					linkedSizes: true,
					width: null,
					height: null,
					range: null
				},
				loaders: {
					loadingImage: false
				}
			};
		},
		computed: {
			toolbar() {
				return this.tools.split("|").map(tool => {
					if (tool == "V" && this.varsList == null) return null;
					return ({
						B: {
							id: "B",
							label: "Negrito",
							icon: "fas fa-bold",
							active: this.toolState.B,
							action: () => document.execCommand("bold")
						},
						I: {
							id: "I",
							label: "Itálico",
							icon: "fas fa-italic",
							active: this.toolState.I,
							action: () => document.execCommand("italic")
						},
						U: {
							id: "U",
							label: "Sublinhado",
							icon: "fas fa-underline",
							active: this.toolState.U,
							action: () => document.execCommand("underline")
						},
						S: {
							id: "S",
							label: "Tachado",
							icon: "fas fa-strikethrough",
							active: this.toolState.S,
							action: () => document.execCommand("strikeThrough")
						},
						A: {
							id: "A",
							label: "Criar link",
							icon: "fas fa-link",
							active: this.toolState.A,
							action: () => {
								let url = prompt("URL:");
								if (url != null) document.execCommand("createLink", false, url);
							},
							onActive: {
								label: "Remover link",
								icon: "fas fa-unlink active",
								action: () => document.execCommand("unlink")
							}
						},
						IMG: {
							id: "IMG",
							label: "Inserir imagem",
							icon: "far fa-image",
							active: this.toolState.IMG,
							action: () => { }
						},
						V: {
							id: "V",
							label: `Inserir ${this.varsLabel ?? "variável"}`,
							icon: "fas fa-database",
							active: this.toolState.V,
							action: () => this.toolState.V = !this.toolState.V,
							insertVariable: value => {
								document.execCommand("insertText", false, value);
								this.toolState.V = false;
							}
						}
					})[tool];
				}).filter(tool => tool != null);
			}
		},
		watch: {
			content(value) {
				if (this.$refs.editor != document.activeElement) this.updateHtml(value);
			},
			varsList() {
				this.updateHtml(this.content);
			},
			"loadedImage.width"() {
				if (this.$refs["image-height"] == document.activeElement || !this.loadedImage.file || !this.loadedImage.linkedSizes) return;
				this.loadedImage.height = parseInt((this.loadedImage.file.height / this.loadedImage.file.width) * this.loadedImage.width);
			},
			"loadedImage.height"() {
				if (this.$refs["image-width"] == document.activeElement || !this.loadedImage.file || !this.loadedImage.linkedSizes) return;
				this.loadedImage.width = parseInt((this.loadedImage.file.width / this.loadedImage.file.height) * this.loadedImage.height);
			}
		},
		methods: {
			inputContent() {
				this.$emit("input", this.getHtml());
				this.updateHtml();
			},
			getHtml() {
				if (this.$refs.editor.querySelectorAll(".html-editor-variable").length > 0) {
					let content = document.createElement("div");
					content.innerHTML = this.$refs.editor.innerHTML;
					content.querySelectorAll(".html-editor-variable").forEach(item => {
						item.insertAdjacentText("afterend", atob(item.getAttribute("data-value")));
						item.remove();
					});
					return content.innerHTML;
				}
				return this.$refs.editor.innerHTML;
			},
			updateHtml(content) {
				if (!content && this.varsList && this.$refs.editor == document.activeElement && this.varsList.length > 0) {
					let selection = window.getSelection(),
						caret = (_ => {
							return {
								node: _.focusNode,
								position: _.getRangeAt(0).startOffset,
							}
						})(selection),
						varOfCaret = this.varsList.filter(item => caret.node.textContent.substring(caret.position - item.value.length, caret.position + item.value.length).search(item.value) != -1)[0];
					if (varOfCaret) {
						let startSearch = caret.position - varOfCaret.value.length < 0 ? 0 : caret.position - varOfCaret.value.length,
							position = startSearch + caret.node.textContent.substring(startSearch).search(varOfCaret.value),
							range = document.createRange();
						range.setStart(caret.node, position);
						range.setEnd(caret.node, position + varOfCaret.value.length);
						selection.removeAllRanges();
						selection.addRange(range);
						range.deleteContents();
						let node = document.createElement("span");
						node.innerHTML = varOfCaret.label;
						node.classList.add("html-editor-variable");
						node.setAttribute("data-value", btoa(
							varOfCaret.value));
						node.setAttribute("contenteditable", false);
						range.insertNode(node);
						selection.collapseToEnd();
						return;
					}
					let random = Math.random();
					selection.getRangeAt(0).insertNode(document.createTextNode(random));
					this.$refs.editor.innerHTML = (content => {
						this.varsList.forEach(item => {
							content = content.replaceAll(item.value, `<span contenteditable="false" class="html-editor-variable" data-value="${btoa(item.value)}">${item.label}</span>`);
						});
						return content;
					})(this.$refs.editor.innerHTML);
					window.find(random);
					selection.getRangeAt(0).deleteContents();
				}
				if (content || content == "") this.$refs.editor.innerHTML = (() => {
					if (this.varsList) this.varsList.forEach(item => {
						content = content.replaceAll(item.value, `<span contenteditable="false" class="html-editor-variable" data-value="${btoa(item.value)}">${item.label}</span>`);
					});
					return content;
				})();
			},
			getCaretParents() {
				let parents = [],
					container = (container => {
						if (!container) return;
						return container.nodeType == Node.TEXT_NODE ? container.parentNode : container;
					})(window.getSelection().anchorNode);
				if (container == null || !this.$refs.editor.contains(container)) return [];
				do {
					if (container == null) break;
					parents.push(container);
					container = container.parentNode;
				}
				while (!container || container != this.$refs.editor);
				return parents;
			},
			caretIsInsideTag(tag) {
				return this.getCaretParents().map(parent => parent.tagName).includes(tag);
			},
			updateTools() {
				if (this.$refs.editor != document.activeElement) return;
				this.getCaretParents();
				this.tools.split("|").forEach(tool => (({
					B: () => this.toolState.B = document.queryCommandState("bold"),
					I: () => this.toolState.I = document.queryCommandState("italic"),
					U: () => this.toolState.U = document.queryCommandState("underline"),
					S: () => this.toolState.S = document.queryCommandState("strikeThrough"),
					A: () => this.toolState.A = this.caretIsInsideTag("A")
				})[tool] ?? (() => { }))());
			},
			toolAction(action, e) {
				if (e.which != 1) return;
				action();
				this.updateTools();
			},
			getToolData(id) {
				return this.toolbar.filter(tool => tool.id == id)[0];
			},
			loadImage() {
				if (this.$refs["file-input"][0].files.length == 0) {
					this.resetInsertImageModal();
					return;
				}

				// Armazena temporariamente a posição atual do caret para inserir a imagem nesta posição.
				if(this.$refs.editor == document.activeElement) this.loadedImage.range = window.getSelection().getRangeAt(0);
				
				this.$bvModal.show("insert-image");
				let url = URL.createObjectURL(this.$refs["file-input"][0].files[0]),
					img = new Image();
				img.onload = () => {
					this.$nextTick(() => {
						this.loadedImage.file = img;
						this.linkImageSizes();
					});

				};
				img.src = url;
			},
			linkImageSizes() {
				this.loadedImage.linkedSizes = false;
				let img = this.loadedImage.file,
					containerSize = {
						width: this.$refs["image-container"].offsetWidth,
						height: this.$refs["image-container"].offsetHeight
					};
				if ((img.width / containerSize.width) > img.height / (containerSize.height)) {
					this.loadedImage.width = parseInt(img.width > containerSize.width ? containerSize.width : img.width);
					this.loadedImage.height = parseInt(this.loadedImage.width / (img.width / img.height));
				} else {
					this.loadedImage.height = parseInt(img.height > containerSize.height ? containerSize.height : img.height);
					this.loadedImage.width = parseInt(this.loadedImage.height * (img.width / img.height));
				}

				this.$nextTick(() => {
					this.loadedImage.linkedSizes = true;
				});
			},
			async insertImage() {
				this.loaders.loadingImage = true;
				let form = new FormData(),
					file = this.$refs["file-input"][0].files[0];
				form.append("arquivo", file, file.name);

				let url = await axios.post("api/midia/criar-url", form).then(response => response.data).catch(() => {
					this.$bvToast.toast(`Houve um erro ao tentar processar a imagem "${file.name}".`, {
						title: "Ops, algo deu errado...",
						toaster: "b-toaster-top-right",
						variant: "danger",
						solid: true,
						autoHideDelay: 5000
					});
					return null;
				});

				this.loaders.loadingImage = false;

				if (url) {
					// Recupera a posição que é para inserir a imagem ou a define para o final do componente.
					let range = this.loadedImage.range;
					if (!range) {
						range = document.createRange();
						range.selectNodeContents(this.$refs.editor);
						range.collapse(false);
					}

					// Coloca o caret na posição definida.
					this.$refs["editor"].focus();
					let selection = window.getSelection();
					selection.removeAllRanges();
					selection.addRange(range);

					// Insere a imagem na posição definida.
					document.execCommand("insertHTML", false, `<img src="${url}" width="${this.loadedImage.width}" height="${this.loadedImage.height}" />`);

					this.$bvModal.hide("insert-image");
				}
			},
			resetInsertImageModal() {
				this.$refs["file-input"][0].value = null;
				this.loadedImage.file = null;
				this.loadedImage.linkedSizes = true;
				this.loadedImage.width = null;
				this.loadedImage.height = null;
				this.loadedImage.range = null;
			},
			focus() {
				this.$nextTick(() => {
					this.$refs.editor.focus();
				});
			}
		},
		mounted() {
			this.updateHtml(this.content);
			if (this.$refs["editor"]) this.$refs["editor"].onpaste = e => {
				if (!this.plainTextOnPaste) return;
				e.preventDefault();
				let text = (e.originalEvent ?? e).clipboardData.getData("text/plain");
				document.execCommand("insertText", false, text);
			};
		}
	}
</script>
<style scoped>
	div[contenteditable] {
		outline-style: none;
		cursor: text;
		position: relative;
	}

	.html-editor {
		position: relative;
	}

	.html-editor-tools {
		display: none;
		position: absolute;
		margin-top: 5px;
		background-color: #fff;
		padding: 2px;
		z-index: 1000;
		white-space: nowrap;
		box-shadow: 0 0 3px 0 rgba(0 0 0 / .5);
	}

		.html-editor-tools > i {
			padding: 5px;
			background-color: #fff;
			width: 25px;
			font-size: 12px;
			text-align: center;
			border: 1px transparent solid;
			transition: all ease-in-out .3s;
			position: relative;
		}

			.html-editor-tools > i.active {
				color: var(--cor-primaria-cliente);
				border-color: var(--cor-primaria-cliente);
			}

				.html-editor-tools > i.active:hover {
					background-color: var(--cor-primaria-cliente);
					color: #fff;
				}

			.html-editor-tools > i:hover {
				background-color: var(--cinza-3);
			}

			.html-editor-tools > i:active {
				background-color: var(--cinza-3);
			}

		div[contenteditable]:focus + .html-editor-tools, .html-editor-tools:hover {
			display: block;
		}

	.html-editor-variable-list {
		border-top: 1px var(--cinza-4) solid;
		cursor: default;
	}

		.html-editor-variable-list > ul {
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;
		}

			.html-editor-variable-list > ul > li {
				padding: 0 3px;
			}

				.html-editor-variable-list > ul > li:hover {
					color: var(--cor-primaria-cliente);
				}

	.html-editor[fixed] > .html-editor-tools {
		position: relative;
		display: table;
		box-shadow: none;
		width: auto;
		margin-top: 0;
		padding: 5px 2px;
	}

		.html-editor[fixed] > .html-editor-tools > i {
			font-size: 15px;
			padding: 5px 7px;
			width: auto;
		}

	.html-editor[fixed] .html-editor-variable-list {
		position: absolute;
		background-color: #fff;
		border-top: 0;
		font-size: 13px;
		overflow-y: auto;
		padding: 3px 5px;
		box-shadow: 1px 1px 5px 0 #777;
	}

	.html-editor[fixed] > div[contenteditable] {
		background-color: #fbfbfb;
		border: 1px var(--cinza-4) solid;
		padding: 10px 7px;
		min-height: 120px;
		height: calc(100% - 32px);
		max-height: 300px;
		overflow-x: auto;
		transition: all ease-in-out .3s;
	}

		.html-editor[fixed] > div[contenteditable]:focus {
			box-shadow: 0 0 2px 0 #a9a9a9 inset;
		}

	.placeholder {
		position: absolute;
		opacity: .7;
	}

	#file-input {
		display: none;
	}

	#file-input + label {
		position: absolute;
		display: block;
		opacity: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: inherit;
	}

	#select-image-container {
		position: relative;
		background-color: #333;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 300px;
		margin-bottom: 12px;
		overflow: hidden;
	}

		#select-image-container > i {
			font-size: 200px;
			color: var(--cinza-2);
		}

		#select-image-container > div {
			display: flex;
			justify-content: flex-start;
			overflow: auto;
			max-width: 100%;
			max-height: 100%;
		}
</style>
<style>
	div[contenteditable] a, div[contenteditable] a:hover {
		text-decoration: none;
	}
</style>